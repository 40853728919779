exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lesson-tsx": () => import("./../../../src/pages/lesson.tsx" /* webpackChunkName: "component---src-pages-lesson-tsx" */),
  "component---src-pages-schedule-tsx": () => import("./../../../src/pages/schedule.tsx" /* webpackChunkName: "component---src-pages-schedule-tsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-videos-tsx": () => import("./../../../src/pages/videos.tsx" /* webpackChunkName: "component---src-pages-videos-tsx" */)
}

